import React from 'react'
import styled from 'styled-components'

export const BodyWrapper = styled.div`
  position: relative;
  max-width: 400px;
  width: 100%;
  background: ${({ theme }) => theme.bg1};
  border-radius: 8px;
  padding: 1rem;

  // Green neon shadow effect
  box-shadow:
    0 0 3px #0fa,  // Light green glow
    0 0 6px #0fa,  // Light green glow
    0 0 9px #0fa,  // Light green glow
    0 0 12px #131010,  // Light green glow
    0 0 15px #0fa,  // Light green glow
    0 0 10px #0fa,  // Light green glow
    0 0 3px #0fa; // Light green glow
`;


/**
 * The styled container element that wraps the content of most pages and the tabs.
 */
export default function AppBody({ children }: { children: React.ReactNode }) {
  return <BodyWrapper>{children}</BodyWrapper>
}
